import React from "react";
import { Link } from "react-router-dom";

const HomeAppointmentBTNComponent = () => {
  return (
    <section
      className="ftco-intro-2 img"
      style={{backgroundImage: "url(assets/images/bg_1.jpg)"}}
    >
      <div className="overlay"></div>
      <div className="container-xl">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-10">
            <div className="row">
              <div className="col-md-8 d-flex align-items-center">
                <div>
                  <span className="subheading">Book your appointment</span>
                  <h1 className="mb-md-0 mb-4">
                    Do not wait make an appointment today!
                  </h1>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <p className="mb-0">
                  <Link to="/appointment" className="btn btn-white py-md-4 py-3 px-md-5 px-4">
                    Make An Appointment
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeAppointmentBTNComponent;
