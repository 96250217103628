import axios from "axios";
import { linkUrl } from "../utilies/linkUrl";


const aboutService = async () => {
    const res = await axios.get(`${linkUrl}/aboutus`);
    if (res.data.status === 200) {
        return res.data.data[0];
    }
}

export default aboutService;
