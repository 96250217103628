import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { linkUrl } from "../../utilies/linkUrl";

const SliderComponent = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    const sliderData= async()=>{
      setIsLoading(true);
      const res = await axios.get(`${linkUrl}/slider`);
      if (res.data.status === 200) {
          setData(res.data.data) ;
          setIsLoading(false);
      }
      else{
          setIsLoading(false);
      }

  };
  sliderData()


  },[])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };


  return (
    <section className="">
      <div className="overlay"></div>
      <div className="hero-slider">
        <div className="item">
          <div className="work">
            <div
              className="img img2 align-items-center js-fullheight"
             
            >
              {/* <div className="container-xl">
                <div className="row">
                  <div className="col-md-12 col-lg-10">
                    <div className="row">
                      <div className="col-md-8 col-lg-6">
                        <div className="text mt-md-5">
                          <h1 className="mb-4">
                            Dentist Services that You Can Trust
                          </h1>
                          <p className="mb-4">
                            Far far away, behind the word mountains, far from
                            the countries Vokalia and Consonantia, there live
                            the blind texts.
                          </p>
                          <p>
                            <a href="#" className="btn btn-primary p-4 py-3">
                              See Our Services{" "}
                              <span className="ion-ios-arrow-round-forward"></span>
                            </a>{" "}
                            <a href="#" className="btn btn-white p-4 py-3">
                              View Course{" "}
                              <span className="ion-ios-arrow-round-forward"></span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

<Slider {...settings}>
          {
            data && data.sliders.length > 0 && data.imgurl &&
            data.sliders.slice(0,3).map((s,idx)=>(
              <div key={idx}>
              <img src={`${data.imgurl}/${s.slider_img}`} className='img-fluid custom-slide' width="100%" height="50%" alt='' />
             </div>

            ))

          }
    
        </Slider>
            </div>
          </div>
        </div>
        <div className="item">
     
        </div>
      </div>
    </section>
  );
};

export default SliderComponent;
