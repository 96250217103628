import axios from "axios";
import React, { useEffect, useState } from "react";
import HeaderBannerComponent from "../components/common/HeaderBannerComponent";
import HomeAppointmentBTNComponent from "../components/HomeComponent/HomeAppointmentBTNComponent";
import siteSetting from "../services/siteSettingService";
import { linkUrl } from "../utilies/linkUrl";
import MessageBox from "../utilies/MessageBox";

const ContactPage = () => {
  const [contact, setContact] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [succmesg, setSuccmesg] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const settingData = async () => {
      setIsLoading(true);
      const sitedata = await siteSetting();
      if (sitedata) {
        setContact(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    settingData();
  }, []);

  const submitValue=async(e)=>{
    e.preventDefault();
    setIsLoading(true);
    const contactForm = {
        name,
        email,
        subject,
        message
    }
    if (contactForm !== null) {
        try {
            const res = await axios.post(`${linkUrl}/contactus`, contactForm);
            setFullName("");
            setEmail("");

            setSubject("");
            setMessage("");
            setIsLoading(false);
            if (res.data.status === 200) {

                setIsLoading(false);
                setSuccmesg("We Receive your message")
                //  setOffers(res.data.data[0]);
                setError(null);
            }

        } catch (err) {

            setError("Something went worng ");
            setIsLoading(false);
            // setOffers(null);
        }
        finally {
            setIsLoading(false);
        }
    }
    setFullName("");
    setEmail("");

    setSubject("");
    setMessage("");

}
  return (
    <>
      <HeaderBannerComponent title="Contact Us" pathtitle="Contact Us" />

      <section className="ftco-section bg-light">
        <div className="container-xl">
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-12">
              <div className="wrapper">
                <div className="row g-0">
                  <div className="col-lg-12">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3>Contact us</h3>
                      <p className="mb-4">
                        We're open for any suggestion or Any Questions?
                      </p>
                      {
                                        error ? (
                                            <MessageBox varient="danger">{error}</MessageBox>
                                        ) : ""

                                    }

                                    <>
                                        {
                                            succmesg ? (
                                                <MessageBox varient="success">{succmesg}</MessageBox>
                                            ) : ""

                                        }
                                    </>
                      <div className="row mb-4">
                        <div className="col-md-4">
                          <div className="dbox w-100 d-flex align-items-start">
                            <div className="text">
                              {contact && contact.site_address && (
                                <p style={{ color: "#00bcd4" }}>
                                  <span>Address:</span>
                                  {contact && contact.site_address
                                    ? contact.site_address
                                    : ""}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="dbox w-100 d-flex align-items-start">
                            <div className="text">
                              <p style={{ color: "#00bcd4" }}>
                                <span>Email:</span>{" "}
                                {contact && contact.site_email
                                  ? contact.site_email
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="dbox w-100 d-flex align-items-start">
                            <div className="text">
                              <p style={{ color: "#00bcd4" }}>
                                <span>Phone:</span>{" "}
                                {contact && contact.site_phone
                                  ? contact.site_phone
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form
                        id="contactForm"
                        name="contactForm"
                        className="contactForm"
                        onSubmit={submitValue}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                              
                                id="name"
                                placeholder="Name"
                                name="name"
                                value={name}
                                onChange={e => setFullName(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                             
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                placeholder="Subject"

                                value={subject}
                                onChange={e => setSubject(e.target.value)}
                             
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                cols="30"
                                rows="7"
                                placeholder="Create a message here"
                       
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="submit"
                                value="Send Message"
                                className="btn btn-primary"
                              disabled={isLoading===true?true:false}
                                
                              />
                              <div className="submitting"></div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="w-100 social-media mt-5">
                        <h3>Follow us here</h3>
                        <p>

                        {contact && contact.site_fb_link && (
           
                    <a
                      href={contact.site_fb_link ? contact.site_fb_link : ""}
                      target="_blank"
                    >
                     Facebook
                    </a>
                  
                )}
       
                        {contact && contact.site_tw_link && (
                        <a
                      href={contact.site_tw_link ? contact.site_tw_link : ""}
                      target="_blank"
                    >
                     Twitter
                    </a>
                        )}
                  

                          {contact && contact.site_in_link && (
          
                    <a href={contact.site_in_link ? contact.site_in_link : ""}>
                     Instagram
           </a>
                )}
                        {contact && contact.site_lin_link && (
      
                    <a
                      href={contact.site_lin_link ? contact.site_lin_link : ""}
                    >
                      Linkedin
                    </a>
              
                )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex align-items-stretch">
                    <div id="map" className="bg-white">
                  
                      <iframe
                       src={
                        contact && contact.site_map ? contact.site_map : ""
                      }
                        width="600"
                        height="450"
                       className="position-relative w-100 h-100"
                       allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <HomeAppointmentBTNComponent />
    </>
  );
};

export default ContactPage;
