import axios from "axios";
import React, { useEffect, useState } from "react";
import appointmentService from "../../services/appointmentService";
import { linkUrl } from "../../utilies/linkUrl";
import MessageBox from "../../utilies/MessageBox";

const AppointmentComponent = () => {
  const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [appoDate, setAppoDate] = useState('');
    const [phone, setphone] = useState('');


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [succmesg, setSuccmesg] = useState(null);
    const [appoDetail, setAppoDetail] = useState();


    useEffect(()=>{
    
        const appointmentData= async()=>{
            setLoading(true);
            const service = await appointmentService()
            if(service) {
                setAppoDetail(service) ;
                setLoading(false);
            }else{
                setLoading(false);
            }

        }
        appointmentData()
    },[])
    useEffect(()=>{
      window.scrollTo(0, 0);
  },[])


  const submitValue = async (e) => {

    e.preventDefault();
    setLoading(true);
    const appointmentForm = {
        fullName,
        email,
        appoDate,
        phone,
  
    }

console.log(appointmentForm);

    if (appointmentForm !== null) {
        try {
            const res = await axios.post(`${linkUrl}/appointment`, appointmentForm);
            setFullName("");
            setEmail("");
            setAppoDate("");
            setphone("");
            
            setLoading(false);
            if (res.data.status === 200) {
                setLoading(false);
                setSuccmesg("We Receive Your Appointment Request")
                //  setOffers(res.data.data[0]);
                setError(null);
            }

        } catch (err) {

            setError("Something went worng or Appointment date not found");
            setLoading(false);
            // setOffers(null);
        }
        finally {
            setLoading(false);
        }
    }

    setFullName("");
    setEmail("");
    setAppoDate("");
    setphone("");
}
const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate() + 1).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container-xl">
        <div className="row g-xl-5">
          <div className="col-md-6 d-flex align-items-stretch">
            <form
            onSubmit={submitValue}
              className="appointment aside-stretch pe-lg-5 d-flex align-items-center"
            >
              <div>
                <span className="subheading">We Are Here For You</span>
                <h2 className="mb-5 appointment-head">Make An Appointment</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="subject">Your Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Full Name"
                        name="fullName"
                        value={fullName}
                        onChange={e => setFullName(e.target.value)} required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="subject">Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)} required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="subject">Phone Number</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Your Phone Number"
                        name="phone"
                        value={phone}
                        onChange={e => setphone(e.target.value)} 
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="subject">Appointment Date</label>
                      <input
                        type="date"
                        className="form-control arrival_date"
                        placeholder="Appointment Date"
                        name="appoDate" 
                        min={disablePastDate()}
                             value={appoDate}
                             onChange={e => setAppoDate(e.target.value)}
                             required
                      />
                    </div>
                  </div>
      
                  {/* <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="subject">Message</label>
                      <textarea
                        name=""
                        id=""
                        cols="0"
                        rows="5"
                        className="form-control"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div> */}
                  <div className="col-md-12 d-flex align-items-center">
                    {
                      loading===false &&
                      <div className="form-group">
                      <input
                        type="submit"
                        value="Send message"
                        className="btn btn-white py-3 d-block rounded"
                      
                      />
                    </div>

                    }
          
                  </div>

                </div>
              </div>
            </form>
          </div>
         
          <div className="col-md-6 heading-section d-flex align-items-center">
        
            <div className="mt-0 my-lg-5 py-5">
            {
                                    error ? (
                                        <MessageBox varient="danger">{error}</MessageBox>
                                    ) : ""

                                }

                                <>
                                    {
                                        succmesg ? (
                                            <MessageBox varient="success">{succmesg}</MessageBox>
                                        ) : ""

                                    }
                                </>
              <span className="subheading">Learn Anything</span>
              <h2 className="mb-2">We Offer Best Dental Services</h2>
              <div className="row mt-4 g-lg-2">
                <div className="col-lg-12 d-flex align-items-stretch services-wrap">
                  <div className="services d-flex">
                    <div className="icon">
                      <span className="flaticon-booking"></span>
                    </div>
                    <div className="text">
                    {
                    appoDetail && appoDetail.appointment_title &&
                                <h2>{appoDetail.appointment_title}</h2>

                            }
                             {
                             appoDetail && appoDetail.appointment_des &&
                        <p className="text-white mb-5" dangerouslySetInnerHTML={{ __html: appoDetail.appointment_des }}></p>


                        }
                     
          
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppointmentComponent;
