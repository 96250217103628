import React, { useEffect, useState } from "react";
import aboutService from "../../services/aboutServices";
import { imgUrl } from "../../utilies/linkUrl";

const HomeAboutComponent = () => {
  const [about, setAbout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
  
      const aboutData= async()=>{
          setIsLoading(true);
          const about_data = await aboutService()
          if(about_data) {
              setAbout(about_data) ;
              setIsLoading(false);
          }else{
              setIsLoading(false);
          }

      }
      aboutData()
  },[])
  return (
    <>
      <section className="ftco-section ftco-no-pt ftco-no-pb">
        <div className="container-xl">
          <div className="row g-xl-5">
            <div
              className="col-md-6  align-items-stretch">
                {
                    about && about.about_img && 
                    <img className="img w-100" src={about.about_img ? `${imgUrl}${about.about_img}` : ""} style={{ objectFit: "cover" }} />
                   
                }
           
            </div>
            <div
              className="col-md-6 heading-section d-flex align-items-center"
    
            >
              <div className="mt-0 my-lg-5 py-5">
                <span className="subheading">Welcome to our Dental Clinic</span>
                {
                                 about && about.about_title && 
                                 <h2 className="mb-2">
                  
                  {about.about_title}
                               </h2>
                }
                 {
                            about && about.about_des &&
                                            <>
                                                <span className="mb-4" dangerouslySetInnerHTML={{ __html: about.about_des.slice(0, 500) + '.......' }} >
                                                </span>
                                            </>
                        }
      
       
             
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAboutComponent;
