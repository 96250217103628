import React, { useEffect, useState } from "react";
import HeaderBannerComponent from "../components/common/HeaderBannerComponent";
import HomeAppointmentBTNComponent from "../components/HomeComponent/HomeAppointmentBTNComponent";
import aboutService from "../services/aboutServices";
import { imgUrl } from "../utilies/linkUrl";

const AboutUsPage = () => {
  const [about, setAbout] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const aboutData = async () => {
      setIsLoading(true);
      const about_data = await aboutService();
      if (about_data) {
        setAbout(about_data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    aboutData();
  }, []);
  return (
    <>
      <HeaderBannerComponent title="About Us" pathtitle="About Us" />

      <section className="ftco-section ftco-no-pt ftco-no-pb">
        <div className="container-xl">
          <div className="row g-xl-5" style={{ alignItems:"center" }}>
            <div className="col-md-6  align-items-stretch">
              {about && about.about_img && (
                <img
                  className="img w-100"
                  src={about.about_img ? `${imgUrl}${about.about_img}` : ""}
                  style={{ objectFit: "cover" }}
                />
              )}
            </div>
            <div className="col-md-6 heading-section d-flex align-items-center">
              <div className="mt-0 my-lg-5 py-5">
                <span className="subheading">Welcome to our Dental Clinic</span>
                {about && about.about_title && (
                  <h2 className="mb-2">{about.about_title}</h2>
                )}
                {about && about.about_des && (
                  <>
                    <span
                      className="mb-4"
                      dangerouslySetInnerHTML={{ __html: about.about_des }}
                    ></span>
                  </>
                )}
              </div>
            </div>
          </div>


          <div className="row" style={{ boxShadow: "1px -2px 2px" }}>
        <div className="col-md-6" style={{ backgroundColor: "#00bcd4", color:"#fff"}}>
          <div className="mb-4">
            {about && about.ceo_msg && (
              <>
                <h4
                  className="d-inline-block text-primary text-uppercase border-5"
                  style={{ borderBottom: "2px solid #3f5ae1", textAlign: "center"}}
                >
                  Message From Us
                </h4>
                <img
                  className="rounded mx-auto d-block"
                  src={about.ceo_img ? `${imgUrl}${about.ceo_img}` : ""}
                  style={{ objectFit: "cover" ,margin:"auto"}}
                  width="100"
                />

                <p
                  className="mb-4 text-justify text-dark"
                  dangerouslySetInnerHTML={{ __html: about.ceo_msg }}
                ></p>
              </>
            )}
          </div>
        </div>
        <div className="col-md-6">
          {about && about.mission && (
            <>
              <h4
                className="d-inline-block text-primary text-uppercase border-5"
                style={{ borderBottom: "2px solid #3f5ae1",textAlign: "center" }}
              >
                Mission
              </h4>

              <p
                className="mb-4 text-justify text-dark"
                dangerouslySetInnerHTML={{ __html: about.mission }}
              ></p>
            </>
          )}

          {about && about.vision && (
            <>
              <h4
                className="d-inline-block text-primary text-uppercase border-5"
                style={{ borderBottom: "2px solid #3f5ae1",textAlign: "center" }}
              >
                Vision
              </h4>

              <p
                className="mb-4 text-justify text-dark"
                dangerouslySetInnerHTML={{ __html: about.vision }}
              ></p>
            </>
          )}
        </div>
      </div>
        </div>
      </section>

   

      <HomeAppointmentBTNComponent />
    </>
  );
};

export default AboutUsPage;
