import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import siteSetting from "../../services/siteSettingService";
import { imgUrl } from "../../utilies/linkUrl";

const TopBarComponent = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const settingData = async () => {
      setIsLoading(true);
      const sitedata = await siteSetting();
      if (sitedata) {
        setData(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    settingData();
  }, []);

  return (
    <>
      
      <div className="top-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md col-xl-4 d-flex align-items-center mb-4 mb-md-0">
              <Link className="navbar-brand align-items-center" to="/">
                <img
                  className="img-fluid"
                  style={{ maxHeight: "70px" }}
                  src={
                    data && data.site_logo && data.site_logo !== null
                      ? `${imgUrl}${data.site_logo}`
                      : ""
                  }
                />
                <span>{data && data.site_title ? data.site_title : ""}</span>
              </Link>
            </div>
            <div className="col-md d-flex align-items-center mb-2 mb-md-0">
              <div className="con d-flex">
                <div className="icon">
                  <span className="flaticon-clock"></span>
                </div>
                <div className="text">
                  <span>Sunday - Friday</span>
                  <strong>9:00AM-6:00PM</strong>
                  <span>Saturday</span>
                  <strong>9:00AM-2:00PM</strong>
                </div>
              </div>
            </div>
            <div className="col-md d-flex align-items-center mb-2 mb-md-0">
              <div className="con d-flex">
                <div className="icon">
                  <span className="flaticon-telephone"></span>
                </div>
                {data && data.site_phone && (
                  <div className="text">
                    <span>Call Us</span>
                    <strong>{data.site_phone ? data.site_phone : ""}</strong>
                    <strong>{data.site_mobile ? data.site_mobile : ""}</strong>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md d-flex align-items-center mb-2 mb-md-0">
              <div className="con d-flex">
                <div className="icon">
                  <span className="flaticon-pin"></span>
                </div>

                {data && data.site_address && (
                  <div className="text">
                    <span>Location</span>
                    <strong>
                      {data.site_address ? data.site_address : ""}
                    </strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBarComponent;
