import React from "react";
import { Link, NavLink } from "react-router-dom";

const NavBarComponent = () => {
  return (
    <nav className="navbar navbar-expand-lg  ftco-navbar-light">
      <div className="container-xl">
        <Link to="/appointment" className="btn-custom order-lg-last">
          Make An Appointment
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="fa fa-bars"></span> Menu
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
            <NavLink to="/" className={({isActive})=>'nav-link'+(isActive ? ' active': '')} end>Home</NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/about-us" className={({isActive})=>'nav-link'+(isActive ? ' active': '')} end>About Us</NavLink>

            </li>
  
            <li className="nav-item">
            <NavLink to="/services" className={({isActive})=>'nav-link'+(isActive ? ' active': '')} end>Services</NavLink>

            </li>
            <li className="nav-item">
            <NavLink to="/our-team" className={({isActive})=>'nav-link'+(isActive ? ' active': '')} end>Our Team</NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/contact-us" className={({isActive})=>'nav-link'+(isActive ? ' active': '')} end>Contact</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBarComponent;
