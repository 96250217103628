import React from 'react'
import { Link } from 'react-router-dom'

const HeaderBannerComponent = ({title,pathtitle}) => {
  return (
    <section
    className="hero-wrap hero-wrap-2"
    style={{ backgroundImage: "url('/assets/images/bg_2.jpg')" }}
  >
    <div className="overlay"></div>
    <div className="container">
      <div className="row no-gutters slider-text align-items-end justify-content-center">
        <div className="col-md-9 text-center mb-5">
          <p className="breadcrumbs">
            <span className="me-2">
              <Link to="/">
                Home <i className="fa fa-chevron-right"></i>
              </Link>
            </span>{" "}
            <span>
              {pathtitle} <i className="fa fa-chevron-right"></i>
            </span>
          </p>
          <h1 className="mb-0 bread">{title}</h1>
        </div>
      </div>
    </div>
  </section>
  )
}

export default HeaderBannerComponent