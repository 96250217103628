import React, { useEffect } from 'react'
import AppointmentComponent from '../components/common/AppointmentComponent'
import HomeAboutComponent from '../components/HomeComponent/HomeAboutComponent'
import HomeAppointmentBTNComponent from '../components/HomeComponent/HomeAppointmentBTNComponent'
import HomeServicesComponent from '../components/HomeComponent/HomeServicesComponent'
import SliderComponent from '../components/HomeComponent/SliderComponent'



const HomePage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <div>
            <SliderComponent/>
            <AppointmentComponent/>
            <HomeServicesComponent/>
            <HomeAboutComponent/>
            <HomeAppointmentBTNComponent/>
    </div>
  )
}

export default HomePage