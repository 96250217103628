import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import siteSetting from "../../services/siteSettingService";

const FooterComponent = () => {
  const [contact, setContact] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const settingData = async () => {
      setIsLoading(true);
      const sitedata = await siteSetting();
      if (sitedata) {
        setContact(sitedata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    settingData();
  }, []);
  return (
    <footer
      className="ftco-footer img"
      style={{ backgroundImage: "url(assets/images/bg_1.jpg)" }}
    >
      <div className="overlay"></div>
      <div className="container-xl">
        <div className="row mb-5 justify-content-between ">
          <div className="col-md-4">
            <div className="ftco-footer-widget mb-4">
              <h2
                className="ftco-heading-2 logo "
                style={{ marginBottom: "5px" }}
              >
                {contact && contact.site_title && (
                  <Link className="align-items-center " href="/">
                    {contact.site_title ? contact.site_title : ""}
                  </Link>
                )}
              </h2>

              <ul className="ftco-footer-social list-unstyled mt-2">
                {contact && contact.site_fb_link && (
                  <li>
                    <a
                      href={contact.site_fb_link ? contact.site_fb_link : ""}
                      target="_blank"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                )}
                {contact && contact.site_tw_link && (
                  <li>
                    <a
                      href={contact.site_tw_link ? contact.site_tw_link : ""}
                      target="_blank"
                    >
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                )}

                {contact && contact.site_in_link && (
                  <li>
                    <a href={contact.site_in_link ? contact.site_in_link : ""}>
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                )}

                {contact && contact.site_lin_link && (
                  <li>
                    <a
                      href={contact.site_lin_link ? contact.site_lin_link : ""}
                    >
                      <span className="fa fa-linkedin"></span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Quick Links</h2>
              <ul className="list-unstyled">
                <li>
                  <Link to="/">
                    <span className="ion ion-ios-arrow-round-forward me-2"></span>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about-us">
                    <span className="ion ion-ios-arrow-round-forward me-2"></span>
                    About Us
                  </Link>
                </li>

                <li>

                  <Link to="/services">
                    <span className="ion ion-ios-arrow-round-forward me-2"></span>
                    Services
                  </Link>
                </li>
                <li>
                  
                  <Link to="/our-team">
                    <span className="ion ion-ios-arrow-round-forward me-2"></span>
                    Our Team
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us">
                    <span className="ion ion-ios-arrow-round-forward me-2"></span>
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Have a Questions?</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    {contact && contact.site_address && (
                      <>
                        <span className="icon fa fa-map marker"></span>

                        <span className="text">
                          {contact.site_address ? contact.site_address : ""}
                        </span>
                      </>
                    )}
                  </li>
                  <li>
                    {contact && contact.site_phone && (
                      <>
                        <span className="icon fa fa-phone"></span>
                        <span className="text">
                          {contact.site_phone ? contact.site_phone : ""}
                        </span>
                      </>
                    )}
                  </li>

                  <li>
                    {contact && contact.site_mobile && (
                      <>
                        <span className="icon fa fa-mobile"></span>
                        <span className="text">
                          {contact.site_mobile ? contact.site_mobile : ""}
                        </span>
                      </>
                    )}
                  </li>

                  <li>
                    {contact && contact.site_email && (
                      <>
                        <span className="icon fa fa-paper-plane"></span>
                        <span className="text">
                          {contact.site_email ? contact.site_email : ""}
                        </span>
                      </>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0 py-5 bg-primary">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="mb-0" style={{ fontSize: "14px" }}>
                Copyright &copy;
                <script
                  data-cfasync="false"
                  src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
                ></script>
                <script>document.write(new Date().getFullYear());</script> All
                rights reserved | This website is made by{" "}
                <a
                  href="https://blackhawk.edu.np/"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  BlackHawk Team
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
