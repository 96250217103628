import React from 'react'
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import AppointmentComponent from './components/common/AppointmentComponent';
import FooterComponent from './components/common/FooterComponent';
import NavBarComponent from './components/common/NavBarComponent';
import TopBarComponent from './components/HomeComponent/TopBarComponent';
import AboutUsPage from './pages/AboutUsPage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import TeamPage from './pages/TeamPage';

const MyRoute = () => {
  return (
    <div>
        <Router>
            <TopBarComponent/>
            <NavBarComponent/>
                <Routes>
                    <Route path='/' element={<HomePage/>}/>
                    <Route path='/about-us' element={<AboutUsPage/>}/>
                    <Route path='/services' element={<ServicesPage/>}/>
                    <Route path='/our-team' element={<TeamPage/>}/>
                    <Route path='/contact-us' element={<ContactPage/>}/>
                    <Route path='/appointment' element={<AppointmentComponent/>}/>

                    
                </Routes>
                <FooterComponent/>
        </Router>

    </div>
  )
}

export default MyRoute