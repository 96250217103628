import axios from "axios";
import { linkUrl } from "../utilies/linkUrl";



const siteSetting = async () => {
    const res = await axios.get(`${linkUrl}/sitesetting`);
    if (res.data.status === 200) {
        return res.data.data[0];
    }
}

export default siteSetting;
