import axios from "axios";
import React, { useEffect, useState } from "react";
import HeaderBannerComponent from "../components/common/HeaderBannerComponent";
import HomeAppointmentBTNComponent from "../components/HomeComponent/HomeAppointmentBTNComponent";
import { imgUrl, linkUrl } from "../utilies/linkUrl";

const TeamPage = () => {
  window.scrollTo(0, 0);
  const [teams, setTeams] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const TeamsData = async () => {
      setIsLoading(true);
      const teams_data = await axios.get(`${linkUrl}/alldoctors`);
      if (teams_data.data.status === 200) {
        setTeams(teams_data.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    TeamsData();
  }, []);
  return (
    <>
      <HeaderBannerComponent title="Our Team" pathtitle="Our Team" />
      
      <section className="ftco-section">
        <div className="container">
          <div className="row">
            {teams &&
              teams.length > 0 &&
              teams.map((t, idx) => (
                <div className="col-md-6 col-lg-3" key={idx}>
                 
                  <div className="card h-100">
                    <div className="staff">

                 
                    <div className="img-wrap d-flex align-items-stretch">
                      {
                         t.doc_img ?
                         <div
                         className="img align-self-stretch"
                         style={{
                           backgroundImage: `url(${imgUrl}${t.doc_img})`,
                         }}
                       ></div>:
                       <div
                       className="img align-self-stretch"
                       style={{
                         backgroundImage: `url(assets/images/doc-1.jpg)`,
                       }}
                     ></div>
                      }
                 
                    </div>
                    <div className="text text-center">
                      
                      <h3 className="mb-2">{t.doc_name?t.doc_name:""}</h3>
                      <span className="position mb-2">{t.position?t.position:""}</span>
                      <span className="position mb-2">{t.department?t.department:""}</span>
                        
                      {
                                t.nmc &&
                            <p className="text-primary">{`${t.nmc?t.nmc:""}`}</p>

                            }
                         
                      
                  
                    </div>
                  </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <HomeAppointmentBTNComponent />
    </>
  );
};

export default TeamPage;
