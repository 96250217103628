import React, { useEffect, useState } from "react";
import servicDataService from "../../services/ourServices";
import { imgUrl } from "../../utilies/linkUrl";

const HomeServicesComponent = () => {
  const [services, setServices] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
  
      const servicesData= async()=>{
          setIsLoading(true);
          const service = await servicDataService()
          if(service) {
              setServices(service) ;
              setIsLoading(false);
          }else{
              setIsLoading(false);
          }

      }
      servicesData()
  },[])
  return (
    <section className="ftco-section bg-light">
      {/* {console.log(services)} */}
      <div className="container-xl">
        <div className="row justify-content-center">
          <div className="col-md-8 heading-section text-center mb-5">
            <span className="subheading">Services</span>
            <h2 className="mb-4">Toothcare Services</h2>
          </div>
        </div>
        <div className="row justify-content-center">

          {
              services && services.length > 0 && services.slice(0, 6).map((service, idx) => (
                <div className="col-md-6 col-lg-3 text-center d-flex align-items-stretch" key={idx}>
                <div className="services-2">
                  <div className="">
                    {/* <span className="flaticon-tooth"></span> */}
                    <img src={`${imgUrl}${service.service_img}`} alt="dental service" height='100%' width='100%'/>
                  </div>
                  <div className="text">
                    <h2>{service.service_name?service.service_name:""}</h2>

                  </div>
                </div>
              </div>

              ))
          }



          {/* <div className="col-md-6 col-lg-3 text-center d-flex align-items-stretch">
            <div className="services-2">
              <div className="icon">
                <span className="flaticon-crown"></span>
              </div>
              <div className="text">
                <h2>Dental Implants</h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 text-center d-flex align-items-stretch">
            <div className="services-2">
              <div className="icon">
                <span className="flaticon-dental-care"></span>
              </div>
              <div className="text">
                <h2>Dental Care</h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 text-center d-flex align-items-stretch">
            <div className="services-2">
              <div className="icon">
                <span className="flaticon-tooth-1"></span>
              </div>
              <div className="text">
                <h2>Teeth Whitening</h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 text-center d-flex align-items-stretch">
            <div className="services-2">
              <div className="icon">
                <span className="flaticon-calculus"></span>
              </div>
              <div className="text">
                <h2>Dental Caculus</h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 text-center d-flex align-items-stretch">
            <div className="services-2">
              <div className="icon">
                <span className="flaticon-removal"></span>
              </div>
              <div className="text">
                <h2>Tooth Removal</h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 text-center d-flex align-items-stretch">
            <div className="services-2">
              <div className="icon">
                <span className="flaticon-calculus"></span>
              </div>
              <div className="text">
                <h2>Removal of Tartar</h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 text-center d-flex align-items-stretch">
            <div className="services-2">
              <div className="icon">
                <span className="flaticon-dental-care-1"></span>
              </div>
              <div className="text">
                <h2>Tooth Inspection</h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default HomeServicesComponent;
