import React, { useEffect, useState } from "react";
import HeaderBannerComponent from "../components/common/HeaderBannerComponent";
import HomeAppointmentBTNComponent from "../components/HomeComponent/HomeAppointmentBTNComponent";
import servicDataService from "../services/ourServices";
import { imgUrl } from "../utilies/linkUrl";

const ServicesPage = () => {
  const [services, setServices] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
  const servicesData= async()=>{
    window.scrollTo(0, 0);
    setIsLoading(true);
    const service = await servicDataService()
    if(service) {
        setServices(service) ;
        setIsLoading(false);
    }else{
        setIsLoading(false);
    }

}
servicesData()
},[])
  return (
    <>
    <HeaderBannerComponent title="Services" pathtitle="Services" />

      <section className="ftco-section bg-light">
        <div className="container-xl">
          <div className="row justify-content-center">
          {
              services && services.length > 0 && services.map((service, idx) => (
                <div className="col-md-6 col-lg-3 text-center d-flex align-items-stretch" key={idx}>
                <div className="services-2">
                  <div className="">
                    {/* <span className="flaticon-tooth"></span> */}
                    <img src={`${imgUrl}${service.service_img}`} alt="dental service" height='100%' width='100%'/>
                  </div>
                  <div className="text">
                    <h2>{service.service_name?service.service_name:""}</h2>

                  </div>
                </div>
              </div>

              ))
          }
         
          </div>
        </div>
      </section>

      <HomeAppointmentBTNComponent/>
    </>
  );
};

export default ServicesPage;
